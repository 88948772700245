import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit {
  document: any;
  public formC: FormGroup;

  constructor(
    private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.formC = fb.group({
      containerId: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  // Simulate GET /containers/:id
  public searchContainer() {

    // Grab values from form
    const containerId = this.formC.get('containerId').value;

    // Submit request to API
    this.api
      .getContainerById({'container_id': containerId})
      .subscribe(
        (response) => {
          this.router.navigate(['container']);
        },
        (error) => {
          // this.alertService.error(error);
        }
      );
  }
}
