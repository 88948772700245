<header class="clearfix">
  <div class="header clearfix">
    <div class="container-fluid">
      <div class="logo">
        <a href="/"><img src="assets/images/logo-1.png" alt=""></a>
      </div>
      <div class="menu">
        <h2>Version : {{version}}</h2>
      </div>
    </div>
  </div>
</header>
<alert></alert>
<section class="login-sec">
  <div class="container">
    <div class="inr">
      <div class="image">
        <img src="assets/images/login-img.png" alt="">
      </div>
      <form [formGroup]="frm">
        <div class="input-box">
          <input type="text" formControlName="username" placeholder="{{login}}">
          <button><i class="fa fa-user"></i></button>
          <div class="float-left text-danger"
               *ngIf="(frm.get('username').invalid && frm.get('username').touched) || showInputErrors"
          >{{errorLogin}}</div>
        </div>
        <div class="input-box">
          <input placeholder="{{password}}" type="password" formControlName="password" required>
          <button><i class="fa fa-lock"></i></button>
          <div class="float-left text-danger"
               *ngIf="(frm.get('password').invalid && frm.get('password').touched) || showInputErrors"
          >{{errorPassword}}</div>
        </div>
        <div class="sign-in-error" *ngIf="hasFailed">
          Invalid username and password.
        </div>
        <button (click)="doSignIn()" [disabled]="isBusy" class="sign-btn">
          <ng-template [ngIf]="!isBusy">Anmelden</ng-template>
          <ng-template [ngIf]="isBusy">Anmelden, bitte warte...</ng-template>
          <img src="assets/icon/arrow-right.png" alt="">
        </button>
      </form>
      <a href="/forgot-password"  class="psw">Passwort vergessen?</a>
    </div>
  </div>
</section>
<footer>
  <div class="footer">
    <div class="container">
      <p>Copyright <i class="fa fa-copyright"></i> {{ dateNow | date:'yyyy'}} Sinfosy Innovation for you</p>
    </div>
  </div>
</footer>
