import { Injectable } from '@angular/core';
import { Container } from '../model/container.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/delay';

@Injectable()
export class ContainerDataService {

  constructor(
    private api: ApiService
  ) {
  }

  // Simulate POST /container
  addContainer(container: Container): Observable<Container> {
    return this.api.createContainer(container);
  }

  // Simulate POST /get_track_container_data
  getContainerData(object): Observable<any> {
    return this.api.getContainerData(object);
  }

  // Simulate GET /containers
  getAllContainer(object): Observable<Container[]> {
    return this.api.getAllContainer(object);
  }

  // // Simulate DELETE /todos/:id
  // deleteTodoById(todoId: number): Observable<Todo> {
  //   return this.api.deleteContainerById(todoId);
  // }
  //
  // // Simulate PUT /todos/:id
  // updateTodo(todo: Todo): Observable<Todo> {
  //   return this.api.updateTodo(todo);
  // }
  //
  // // Simulate GET /todos
  // getAllTodos(): Observable<Todo[]> {
  //   return this.api.getAllTodos().delay(3000);
  // }
  //
  // // Simulate GET /todos/:id
  // getTodoById(todoId: number): Observable<Todo> {
  //   return this.api.getTodoById(todoId);
  // }
  //
  // // Toggle complete
  // toggleTodoComplete(todo: Todo) {
  //   todo.complete = !todo.complete;
  //   return this.api.updateTodo(todo);
  // }
}
