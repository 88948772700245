<header class="clearfix">
  <div class="header-main clearfix">
    <div class="container-fluid">
      <div class="content">
        <ul>
          <li><a href="" class="logo"><img src="assets/images/logo-2.png" alt=""></a></li>
          <li class="text">{{header}}</li>
        </ul>
      </div>
      <div class="menu">
        <ul>
          <li class="header-btn"><span>{{user}}</span></li>
          <li class="img"><img src="assets/images/right-img1.png" alt=""></li>
          <!--<li class="img"><a href="/" data-target="#four" data-toggle="modal"><img src="assets/images/right-img2.png" alt=""></a></li>-->
          <li class="img"><a (click)="doSignOut()" class="img"><img src="assets/images/right-img3.png" alt=""></a></li>
        </ul>
      </div>
    </div>
  </div>
</header>
<alert></alert>
