import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './service/auth.service';
import { AppComponent } from './app.component';

@Injectable({
  providedIn: 'root'
})
export class CanActivateSintraGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private app: AppComponent
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.auth.isSignedIn()) {
      console.error('Access denied - Redirect to sign-in page');
      this.router.navigate(['/login']);
      return false;
    }

    // check the access to urls
    const url: string = state.url;
    const allowedUrls: any[] = [
        '/search-materials',
        '/location-material'
    ];
    this.app.setIsAdmin();
    // @ts-ignore
    if (!this.app.isAdmin && !allowedUrls.some((item) => url.match(new RegExp(item)))) {
      console.error('Access denied - Redirect to search page');
      this.router.navigate(['/search-materials']);
      return false;
    }
    return true;
  }
}
