import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable()
export class AuthService {

  constructor(
    private session: SessionService,
  ) {
  }

  public isSignedIn(): boolean {
    return !!this.session.authKey || !!localStorage.getItem('authKey');
  }

  public doSignOut(): void {
    this.session.destroy();
    localStorage.clear();
  }

  public doSignIn(authKey: string, userName: string, firstName: string, companyId: string, userId: string, role: string): void {
    console.error('Access verified - Redirect to sign-in page' + authKey + ' end');
    if ((!authKey) || (!userName)) {
      return;
    }
    this.session.authKey = authKey;
    this.session.username = userName;
    this.session.firstName = firstName;
    this.session.role = userName;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('authKey', authKey);
    localStorage.setItem('companyId', companyId);
    localStorage.setItem('userId', userId);
    localStorage.setItem(this.session.ITEM_USER_NAME, userName);
    localStorage.setItem(this.session.ITEM_FIRST_NAME, firstName);
    localStorage.setItem(this.session.ITEM_ROLE, role);
  }
}
