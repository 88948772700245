<app-header></app-header>
<main>
  <section class="main-sec clearfix">
    <div class="container">
      <div class="inr clearfix">
        <form [formGroup]="formC">
          <div class="left">
            <div class="input-box">
              <label>Container:</label>
              <input type="text" maxlength="5" id="containerId" formControlName="containerId">
              <button onclick="document.getElementById('containerId').value = ''" class="sign-btn">
                <img src="assets/icon/edit.png" alt="">
              </button>
            </div>
            <ul>
              <li id="redirectlogo">
                <button (click)="searchContainer()" class="sign-btn">
                  <img src="assets/icon/redirect.png" alt="">
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="inr-btn clearfix">
        <div class="photo photo-main">
          <a id="beaconImghref" href="" target="_blank"><img id="beaconImg" src="" alt=""></a>
        </div>
        <div class="button">
          <ul>
            <li id="infologo" class="infologo"><a href="javascript:void(0)" onclick="getcontainerinfo();"><img src="assets/icon/i-icon.png" alt=""></a></li>
            <li><a href="javascript:void(0)" onclick="getbeacon();" class="bg-red" id="read_red">Read</a></li>
            <li><a href="javascript:void(0)" onclick="getstore();">Store</a></li>
            <li><a href="javascript:void(0)" onclick="withdrawstore();">Withdraw</a></li>
            <li class="dropdown"><a href="#" class="dropdown-toggle" data-toggle="dropdown">New Material
            </a>
              <ul class="dropdown-menu">
                <li>
                  <label class="radio-inline">
                    <input onclick="newmaterial();" name="optradio" type="radio">Manual Input
                    <span class="checkmark"></span>
                  </label>
                </li>
                <li>
                  <label class="radio-inline">
                    <input name="optradio" type="radio">Scan Material
                    <span class="checkmark"></span>
                  </label>
                </li>
              </ul>
            </li>
            <li><a href="javascript:void(0)" class="bg-black">Withdraw all</a></li>
          </ul>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
          <tr>
            <th></th>
            <th><img src="assets/icon/tbl-icon1.png" alt="">Number</th>
            <th><img src="assets/icon/tbl-icon2.png" alt="">Material - Nummer</th>
            <th><img src="public/assets/icon/tbl-icon3.png" alt="">Material - Description</th>
            <th></th>
          </tr>
          </thead>
          <tbody id="beacontable">
            </tbody>
        </table>
      </div>
    </div>
  </section>
</main>
