import { Component, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';
import {AppComponent} from '../../app.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {

  @ViewChild('scanner') scanner: ZXingScannerComponent;
  @Output() valueChange = new EventEmitter();


  public noCameraText = 'Keine Kamera ausgewählt.';
  public deviceDoesHaveCameraText = 'Sieht so aus, als hätte Ihr Gerät keine Kameras, oder ich könnte keine finden.';
  public permissionsText = 'Warten auf Berechtigungen.';
  public permissionsWithText = 'Wenn Ihr Gerät über keine Kameras verfügt, werden keine Berechtigungen abgefragt.';
  public deniedPermissionsText = 'Sie haben die Kameraberechtigungen verweigert. Ohne sie können wir nichts scannen. 😪';
  public noDeviceText = 'Kein Gerät';
  public allowedFormats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.EAN_13,
    BarcodeFormat.CODE_128, BarcodeFormat.DATA_MATRIX
  ];
  hasDevices: boolean;
  hasCameras = false;
  hasPermission: boolean;
  qrResultString: string;

  tryHarder = false;
  torchEnabled = false;
  torchAvailable$: Observable<boolean>;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;

  constructor(
      public app: AppComponent,
  ) { }

  ngOnInit(): void {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;
      this.availableDevices = devices;
    });
    this.torchAvailable$ = this.scanner.torchCompatible;
  }

  // Scanning with library
  public handleQrCodeResult(resultString: string) {
    console.log('Result: ', resultString);
    this.qrResultString = resultString;
    this.valueChanged(this.qrResultString);
  }

  public onDeviceSelectChange(selectedValue: string) {
    console.log('Selection changed: ', selectedValue);
    this._selectBackfaceCamera(this.availableDevices);
    const device = this.availableDevices.find(x => x.deviceId === selectedValue);
    this.selectedDevice = device || null;
    if (selectedValue !== '') {
      localStorage.setItem('selectedCamera', selectedValue);
    }
  }

  // The code has been successfully scanned
  public valueChanged(qrResultString) {
    this.valueChange.emit(qrResultString);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  public onCodeResult(resultString: string) {
    console.log(resultString);
    this.qrResultString = resultString;
    this.valueChanged(this.qrResultString);
  }

  private _selectBackfaceCamera(devices: MediaDeviceInfo[]) {
    // selects the devices's back camera by default
    for (const device of devices) {
      if (/back|rear|environment|rück/gi.test(device.label)) {
        this.selectedDevice = device || null;
        break;
      }
    }
  }
}
