import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/delay';
import {Material} from '../model/material.model';
import {MaterialIds} from '../model/material-ids.model';

@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class MaterialDataService {

    constructor(
        private api: ApiService
    ) {
    }

    // Simulate POST /material
    createMaterial(material: Material): Observable<Material> {
        return this.api.createMaterial(material);
    }

    // Simulate POST /edit_container_material
    editMaterial(material: Material): Observable<Material> {
        return this.api.editMaterial(material);
    }

    // POST /search_material
    searchMaterial(material: Material): Observable<Material[]> {
        return this.api.searchMaterial(material);
    }

    // POST /material_tracking_by_location
    searchMaterialByLocation(material: Material): Observable<Material[]> {
        return this.api.searchMaterialByLocation(material);
    }

    // POST /remove_all_container_material
    deleteMaterialsFromContainerById(payload: MaterialIds) {
        return this.api.deleteMaterialsFromContainerById(payload);
    }
}
