import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionService } from './service/session.service';
import { NavigationExtras, Router } from '@angular/router';
import { ModalService} from './service/modal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
// @ts-ignore
export class AppComponent {

    public title = 'BOSCH HTML5 Web App';
    public template = `<img src="../assets/images/load.gif" width="221" height="221" alt=""/>`;
    public isAdmin = false;
    public pleaseWaitText = 'Bitte warten ...';
    public beaconNotAssignedText = 'Die Charge / Vorrichtung ist noch nicht mit einem Beacon verknüpft.';

    // buttons
    public BUTTON_MATERIAL = 'Charge / Vorrichtung mit Beacon-Nr. verknüpfen';
    public BUTTON_MES_ID_MATERIAL = 'Charge mit MES-IDs befüllen';
    public MATERIAL_BUTTON_SEARCH = 'Suchfunktion';

    public FIELD_MATERIAL_NUMBER = 'material_number';
    public FIELD_DESCRIPTION = 'description';
    public FIELD_CONTAINER_ID = 'container_id';
    public FIELD_CONTAINER_NUMBER = 'container_number';
    public FIELD_SERIAL_NUMBER =  'serial_number';
    public FIELD_DEVICE_NUMBER =  'device_number';
    public FIELD_PART_NUMBER = 'part_number';
    public FIELD_PRODUCT_DESCRIPTION =  'product_description';
    public FIELD_LOCATION =  'location';
    public FIELD_MES_ID = 'mes_id';
    public FIELD_MATERIAL_IDS = 'material_ids';
    public activeScanField = this.FIELD_SERIAL_NUMBER;

    public headerText: string;
    public userText: string;
    public name: string;
    public user: string;

    public sessionService;

    public constructor(
        private titleService: Title,
        public session: SessionService,
        private router: Router,
        private modalService: ModalService,
    ) {
        this.sessionService = session;
        this.setIsAdmin();
        this.setUserText();
    }

    public setTitle( newTitle: string) {
        this.titleService.setTitle( newTitle );
    }

    public setHeaderText (text: string) {
        this.headerText = text;
    }

    public setUserText () {
        this.name = localStorage.getItem(this.sessionService.ITEM_USER_NAME) ?
            localStorage.getItem(this.sessionService.ITEM_USER_NAME) : this.sessionService.getUserName;
        this.user = this.isAdmin ? 'ADMIN' : 'MITARBEITER';
        this.userText = this.user  + ' / ' + this.name;
    }

    public setIsAdmin() {
        return this.isAdmin = localStorage.getItem(this.sessionService.ITEM_ROLE) === 'admin' ||
            this.session.authKey === 'admin';
    }

    public goToLocation(containerId) {
        const navigationExtras: NavigationExtras = {
            queryParams: { [this.FIELD_CONTAINER_ID]: containerId }
        };
        this.router.navigate(['location-material'], navigationExtras);
    }


    // Modal methods
    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    // for search in the dropdown
    public searchContainerNumber(item, term) {
        const strZero = new Array(6 - item.length).join('0');
        item = strZero + item;

        return term.container_number.includes(item);
    }
}
