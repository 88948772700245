import {Component, Input, OnInit} from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    @Input() public header: string;
    @Input() public user: string;

    constructor(
    private auth: AuthService,
    private router: Router,
    ) {
    }

    ngOnInit() {
    }

    public doSignOut() {
        this.auth.doSignOut();
        this.router.navigate(['/login']);
    }
}
