<app-header [header]="headerText" [user]="app.userText"></app-header>
<main>
  <section class="section-center">
    <div class="container">
        <div class="row">
          <div class="col-sm-2 bottom-buttons">
            <a *ngIf="app.isAdmin" href="/material" class="left-btn"><span>Charge / Vorrichtung mit Beacon-Nr. verknüpfen</span></a>
            <a *ngIf="app.isAdmin" href="/mesid-material" class="left-btn"><span>Charge mit MES-IDs befüllen</span></a>
            <a href="/search-materials" class="left-btn"><span>Suchfunktion</span></a>
          </div>
          <div class="col-sm-8"><ngx-image-zoom [enableLens]="true" [enableScrollZoom]="true" [zoomMode]="true"
                                                thumbImage="{{layoutThumbPhoto}}"
                                                fullImage="{{layoutPhoto}}"
          ></ngx-image-zoom></div>
          <div class="col-sm-2 bottom-buttons"><a href="/location-material?container_id={{containerId}}" class="right-btn bg-blue"><img src="assets/icon/refresh.png" alt=""> <span>Refresh</span> </a></div>
        </div>
    </div>
  </section>

  <section class="section-form">
    <div class="container">
      <div class="clearfix">
        <div class="row">
          <div class="col-lg-6">
            <div class="inner">
              <div class="mb-5">
                <div class="single-inp">
                  <div class="lft">
                    <label>Chargen-Nr.</label>
                  </div>
                  <div class="mid">
                    <input type="text" id="serial_number" [readonly]="true" [value]="material.serial_number">
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label>Typ-Teile-Nr.</label>
                  </div>
                  <div class="mid">
                    <input type="text" id="device_number" [readonly]="true" [value]="material.device_number">
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label id="vorrich">Vorrichtungs-Nr.</label>
                  </div>
                  <div class="mid">
                    <input type="text" id="part_number" [readonly]="true" [value]="material.part_number">
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label>Beschreibung</label>
                  </div>
                  <div class="mid">
                    <input type="text" id="product_description" [value]="material.product_description">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="inner">
              <div class="single-inp">
                <div class="lft">
                  <label>Lesegerät 1</label>
                </div>
                <div class="mid">
                  <input type="text" [value]="deviceNameFirst">
                </div>
              </div>

              <div class="single-inp">
                <div class="lft">
                  <label>Lesegerät 2</label>
                </div>
                <div class="mid">
                  <input type="text" [value]="deviceNameSecond">
                </div>
              </div>

              <div class="single-inp">
                <div class="lft">
                  <label>Zuletzt gesehen</label>
                </div>
                <div class="mid">
                  <input type="text" [value]="lastSeen">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="materials.length > 0" class="black-table">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-centered">
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
              <tr>
                <th>#</th>
                <th>MES-ID</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let material of materials; let i = index">
                <td>{{i + 1}}</td>
                <td>{{material.mes_id}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
<iframe *ngIf="layoutPhoto === undefined && containerId" [src]="sanitizer.bypassSecurityTrustResourceUrl(isOnIframeUrl)" height="1" width="1"></iframe>