import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContainerComponent } from './container/container.component';
import { CanActivateSintraGuard } from './can-activate-sintra.guard';
import { ContainerResolver } from './resolver/container.resolver';
import { MaterialComponent } from './material/material.component';
import { MesidMaterialComponent } from './mesid-material/mesid-material.component';
import {SearchMaterialsComponent} from './search-materials/search-materials.component';
import {LocationMaterialComponent} from './location-material/location-material.component';
import {AppComponent} from './app.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'material',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'container',
    component: ContainerComponent,
    canActivate: [
      CanActivateSintraGuard
    ],
    // resolve: {
    //   containers: ContainerResolver
    // }
  },
  {
    path: 'material',
    component: MaterialComponent,
    canActivate: [
      CanActivateSintraGuard
    ],
    resolve: {
      containers: ContainerResolver
    }
  },
  {
      path: 'mesid-material',
      component: MesidMaterialComponent,
      canActivate: [
          CanActivateSintraGuard
      ]
  },
  {
      path: 'search-materials',
      component: SearchMaterialsComponent,
      canActivate: [
          CanActivateSintraGuard
      ]
  },
  {
      path: 'location-material',
      component: LocationMaterialComponent,
      canActivate: [
          CanActivateSintraGuard
      ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    CanActivateSintraGuard,
    ContainerResolver,
    AppComponent
  ]
})
export class AppRoutingModule { }
