import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ApiService } from '../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Material } from '../model/material.model';
import { AlertService} from '../service/alert.service';
import { MaterialDataService } from '../service/material-data.service';
import { Container } from '../model/container.model';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {ContainerDataService} from '../service/container-data.service';
import { MaterialIds } from '../model/material-ids.model';
import { AppComponent } from '../app.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ScanComponent } from '../_components/scan/scan.component';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.css']
})
// @ts-ignore
export class MaterialComponent implements OnInit {
    // @ts-ignore
    @Input() containers: Container[];
    // @ts-ignore
    @ViewChild(ScanComponent) scanner: ScanComponent;

    public formMaterial: FormGroup;

    // texts
    public chagreNumberText = 'Chargennummer';
    public vorrichtungNummerText = 'Vorrichtungsnummer';
    public typTeileNummerText = 'Typteilenummer';
    public beschreibungText = 'Beschreibung';
    public successSearchText = 'Die Charge / Vorrichtung ist mit einem Beacon verknüpft.';
    public headerText = 'Charge / Vorrichtung mit Beacon-Nr. verknüpfen';
    private materialsDeleted = 'Die Verknüpfung zum Beacon wurde erfolgreich gelöscht.';
    private materialAssignedToBeacon = 'Charge/Vorrichtung wurde erfolgreich mit dem Beacon verknüpft.';
    private materialHasBeenEdited = 'Charge/Vorrichtung wurde erfolgreich bearbeitet.';
    private selectBeaconError = 'Bitte wählen Sie eine Beacon-Nr. aus.';
    private inputFieldsError = 'Bitte geben Sie eine Chargen-Nr. oder Vorrichtungs-Nr. ein.';

    public isExists = false;
    public material: Material;
    protected materials: Material[] = [];
    protected editMaterials: Material[] = [];
    public idsMaterialDeleteArray: Array<any> = [];

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private alertService: AlertService,
        private materialData: MaterialDataService,
        private containerData: ContainerDataService,
        public app: AppComponent,
        private spinnerService: Ng4LoadingSpinnerService,
    ) {
        this.app.setTitle(this.headerText);
        this.app.setUserText();

        this.formMaterial = fb.group({
          serial_number: ['', Validators.required],
          part_number: ['', Validators.required],
          device_number: [''],
          product_description: [''],
          container_id:  [null, Validators.required]
        });
    }

    ngOnInit() {
        this.getContainers();

        this.formMaterial.valueChanges
            .pipe(debounceTime(1), distinctUntilChanged())
            .subscribe(changes => {
                // it is blocked when the material has been created
                if ((changes.serial_number !== '' && changes.serial_number != null) || this.isExists) {
                    this.formMaterial.get(this.app.FIELD_PART_NUMBER).disable();
                } else {
                    this.formMaterial.get(this.app.FIELD_PART_NUMBER).enable();
                    if (changes.part_number !== '' && changes.part_number != null) {
                        this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).disable();
                    } else {
                        this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).enable();
                    }
                }
            });
    }

    // Simulate GET /containers/:id
    public createMaterial() {
        // Make sure form values are valid
        if (this.checkFormMaterial() === false) {
            return;
        }

        // Grab values from form
        let material = new Material(this.formMaterial.getRawValue());
        // Saving in material_number and description values
        // for being aware where this material was created
        material = this.addMaterialNumberAndDescription(material);
        this.spinnerService.show();

        // Submit request to API
        this.materialData
            .createMaterial(material)
            .subscribe(
                (response) => {
                        this.material = new Material(material);
                        this.getContainers();

                        // Create materials for material.device_number and material.product_description as new materials
                        // because they have to exist in the material's set for the container
                        if (material.device_number) {
                            this.materials.push( new Material({
                                [this.app.FIELD_MATERIAL_NUMBER]: this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER).value,
                                [this.app.FIELD_CONTAINER_ID]: this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value,
                                [this.app.FIELD_DESCRIPTION]: this.typTeileNummerText
                            }));
                        }
                        if (material.product_description) {
                            this.materials.push( new Material({
                                [this.app.FIELD_MATERIAL_NUMBER]: this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION).value,
                                [this.app.FIELD_CONTAINER_ID]: this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value,
                                [this.app.FIELD_DESCRIPTION]: this.beschreibungText
                            }));
                        }

                        // search by the containers the container_number and assign it to material
                        this.containers.forEach((value) => {
                            if (value.container_id === material.container_id) {
                                this.material.container_number = value.container_number;
                            }
                        });
                        this.formMaterial.get(this.app.FIELD_CONTAINER_ID).setValue(this.material.container_id);

                        this.createAdditionalMaterials();
                        this.alertService.success(this.materialAssignedToBeacon);
                        this.isExists = true;
                        this.spinnerService.show();
                    },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    public updateMaterial() {
        // Make sure form values are valid
        if (this.checkFormMaterial() === false) {
            return;
        }
        this.spinnerService.show();
        // Grab values from form
        let material = new Material(this.formMaterial.getRawValue());
        // compare material and material from formMaterial
        // create materials for material.device_number and material.product_description as new materials
        if (this.material.device_number !== material.device_number) {
            const item = new Material({
                [this.app.FIELD_MATERIAL_NUMBER]: this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER).value,
                [this.app.FIELD_CONTAINER_ID]: this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value,
                [this.app.FIELD_DESCRIPTION]: this.typTeileNummerText
            });
            if (this.material.device_number === '') {
                this.materials.push(item);
            }
            this.materialData
                .searchMaterial(new Material({
                    [this.app.FIELD_MATERIAL_NUMBER]: this.material.device_number,
                    [this.app.FIELD_DESCRIPTION]: this.typTeileNummerText,
                    [this.app.FIELD_CONTAINER_ID]: this.material.container_id,
                }))
                .subscribe(
                    (response) => {
                        if (response.length > 0) {
                            response.forEach((value) => {
                                item.material_id = value.material_id;
                            });
                            if (this.material.device_number !== '' && material.device_number !== '') {
                                this.editMaterials.push(item);
                            }
                            if (material.device_number === '') {
                                this.idsMaterialDeleteArray.push(item.material_id);
                            }
                        }
                    },
                    (error) => {
                        this.alertService.error(error);
                    }
                );

        }
        if (this.material.product_description !== material.product_description) {
            const item = new Material({
                [this.app.FIELD_MATERIAL_NUMBER]: this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION).value,
                [this.app.FIELD_CONTAINER_ID]: this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value,
                [this.app.FIELD_DESCRIPTION]: this.beschreibungText
            });
            if (this.material.product_description === '') {
                this.materials.push(item);
            }

            this.materialData
                .searchMaterial(new Material({
                    [this.app.FIELD_MATERIAL_NUMBER]: this.material.product_description,
                    [this.app.FIELD_DESCRIPTION]: this.beschreibungText,
                    [this.app.FIELD_CONTAINER_ID]: this.material.container_id,
                }))
                .subscribe(
                    (response) => {
                        if (response.length > 0) {
                            response.forEach((value) => {
                                item.material_id = value.material_id;
                            });
                            if (this.material.product_description !== '' && material.product_description !== '') {
                                this.editMaterials.push(item);
                            }
                            if (material.product_description === '') {
                                this.idsMaterialDeleteArray.push(item.material_id);
                            }
                        }
                    },
                    (error) => {
                        this.alertService.error(error);
                    }
                );
        }

        // search main material
        this.materialData
            .searchMaterial(new Material({
                [this.app.FIELD_CONTAINER_ID]: this.material.container_id,
            }))
            .subscribe(
                (response) => {
                    if (response.length > 0) {
                        // search by the materials the material_id and assign it to material
                        response.forEach((value) => {
                            if (value.description === this.chagreNumberText || value.description === this.vorrichtungNummerText
                            ) {
                                material = this.addMaterialNumberAndDescription(material);
                                // assign material_id
                                material.material_id = value.material_id;
                            }
                        });
                        // Submit request to API
                        this.materialData
                            .editMaterial(material)
                            .subscribe(
                                (res) => {
                                    this.alertService.success(this.materialHasBeenEdited);
                                    this.isExists = true;
                                    material.container_number = this.material.container_number;
                                    this.material = new Material(material);
                                    this.createAdditionalMaterials();
                                    this.editAdditionalMaterials();
                                    // delete  old additional materials
                                    if (this.idsMaterialDeleteArray.length !== 0) {
                                        this.deleteMaterials(this.idsMaterialDeleteArray);
                                    }
                                    // clean arrays
                                    this.idsMaterialDeleteArray = [];
                                    this.materials = [];
                                    this.editMaterials = [];
                                    this.spinnerService.hide();
                                },
                                (error) => {
                                    this.alertService.error(error);
                                }
                            );
                    }
                },
                (error) => {
                    this.alertService.error(error);
                    this.spinnerService.hide();
                }
            );
    }

    // search material by serial_number and part_number
    public checkMaterial() {
        // Make sure form values are valid
        if (this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).invalid) {
             this.alertService.error(this.inputFieldsError);
             return;
        }
        this.spinnerService.show();
        // Grab values from form
        const material = new Material({
            serial_number: this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).value,
            part_number: this.formMaterial.get(this.app.FIELD_PART_NUMBER).value
        });
        // Submit request to API
        this.materialData
            .searchMaterial(material)
            .subscribe(
                (response) => {
                    if (response.length > 0) {
                        response.forEach((value) => {
                            if (
                                (value.serial_number === material.serial_number || value.part_number === material.part_number)
                                && value.container_id
                            ) {
                                this.isExists = true;
                                this.material = new Material(value);
                                this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER)
                                    .setValue(this.material.serial_number);
                                this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER)
                                    .setValue(this.material.device_number);
                                this.formMaterial.get(this.app.FIELD_PART_NUMBER)
                                    .setValue(this.material.part_number);
                                this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION)
                                    .setValue(this.material.product_description);
                                this.formMaterial.get(this.app.FIELD_CONTAINER_ID)
                                    .setValue(this.material.container_id);
                            }
                        });
                    }
                    if (this.isExists) {
                        this.alertService.success(this.successSearchText);
                    } else {
                        this.app.openModal('custom-modal-1');
                        this.isExists = false;
                    }
                    this.spinnerService.hide();
                },
                (error) => {
                    this.spinnerService.hide();
                    this.alertService.error(error);
                }
            );
    }

    public goToMesId() {
        // save the Material in local storage
        localStorage.setItem('material', JSON.stringify(this.material));
        this.router.navigate(['mesid-material']);
    }

    private getContainers() {
        this.containerData
            .getAllContainer({'without_mathetial' : true})
            .subscribe(
                (response) => {
                    // set disable parameter
                    response.forEach(function(key) {
                        key.setDisabled();
                    });
                    console.log(response);
                    this.containers = response;
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    public reset() {
        this.isExists = false;
        this.formMaterial.reset();
        this.material = new Material();
        this.setEmptyValuesForm();
    }

    // compareObjects(o1: any, o2: any): boolean {
    //     return o1.container_id === o2.container_id && o1.container_number === o2.container_number;
    // }

    // delete all materials from the container, if material_ids is empty it deletes all materials
    public deleteMaterials(ids = []) {
        const payload = new MaterialIds({
            [this.app.FIELD_CONTAINER_ID] : this.material.container_id
        });
        if (ids) {
            payload.material_ids = ids;
        }

        this.materialData
            .deleteMaterialsFromContainerById(payload)
            .subscribe(
                (response) => {
                    if (ids.length === 0) {
                        this.alertService.success(this.materialsDeleted);
                        this.formMaterial.reset();
                        this.material = new Material();
                        this.setEmptyValuesForm();
                        this.getContainers();
                        this.isExists = false;
                    }
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    public openCameraWindow(activeField) {
        // for ios devises
        this.app.openModal('custom-modal-3');
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange(localStorage.getItem('selectedCamera'));
        }
        this.app.activeScanField = activeField;
    }

    public closeCameraWindow() {
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange('');
        }
        this.app.closeModal('custom-modal-3');
    }

    public displayResult(qrResultString) {
        // close pop-up window and switch off camera
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange('');
        }
        this.app.closeModal('custom-modal-3');
        this.formMaterial.get(this.app.activeScanField).setValue(qrResultString);
    }

    public checkFormMaterial() {
        // Make sure form values are valid
        if (this.formMaterial.invalid) {
            if ((this.formMaterial.get(this.app.FIELD_CONTAINER_ID).invalid)) {
                this.alertService.error(this.selectBeaconError);
            } else {
                this.alertService.error(this.inputFieldsError);
            }
            return false;
        }

        return true;
    }

    public addMaterialNumberAndDescription(material) {
        if (material.serial_number) {
            material.material_number = material.serial_number;
            material.description = this.chagreNumberText;
        }
        if (material.part_number) {
            material.material_number = material.part_number;
            material.description = this.vorrichtungNummerText;
        }

        return material;
    }

    public createAdditionalMaterials () {
        // do assigning for these materials
        this.materials.forEach((item: Material, index) => {
            this.materialData
                .createMaterial(item)
                .subscribe(
                    (response) => {
                    },
                    (error) => {
                        this.alertService.error(error);
                    }
                );
        });
        // clean an array
        this.materials = [];
    }

    public editAdditionalMaterials () {
        this.editMaterials.forEach((item: Material, index) => {
            this.materialData
                .editMaterial(item)
                .subscribe(
                    (response) => {
                    },
                    (error) => {
                        this.alertService.error(error);
                    }
                );
        });
        // clean an array
        this.editMaterials = [];
    }

    private setEmptyValuesForm () {
        this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER)
            .setValue('');
        this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER)
            .setValue('');
        this.formMaterial.get(this.app.FIELD_PART_NUMBER)
            .setValue('');
        this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION)
            .setValue('');
        this.formMaterial.get(this.app.FIELD_CONTAINER_ID)
            .setValue('');
    }
}
