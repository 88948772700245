import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContainerComponent } from './container/container.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './service/api.service';
import { AuthService } from './service/auth.service';
import { SessionService } from './service/session.service';
import { ContainerDataService } from './service/container-data.service';
import { HeaderComponent } from './header/header.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { MaterialComponent } from './material/material.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AlertComponent } from './_components/alert.component';
import { ModalComponent } from './_directives/modal.component';
import { ModalService } from './service/modal.service';
import { MesidMaterialComponent } from './mesid-material/mesid-material.component';
import { AutofocusDirective } from './_directives/autofocus.directive';
import { SearchMaterialsComponent } from './search-materials/search-materials.component';
import { LocationMaterialComponent } from './location-material/location-material.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScanComponent } from './_components/scan/scan.component';

@NgModule({
  declarations: [
      AppComponent,
      LoginComponent,
      PageNotFoundComponent,
      ContainerComponent,
      HeaderComponent,
      MaterialComponent,
      AlertComponent,
      ModalComponent,
      MesidMaterialComponent,
      AutofocusDirective,
      SearchMaterialsComponent,
      LocationMaterialComponent,
      ScanComponent
  ],
  imports: [
      AppRoutingModule,
      BrowserModule,
      FormsModule,
      HttpClientModule,
      ReactiveFormsModule,
      Ng4LoadingSpinnerModule.forRoot(),
      NgxImageZoomModule.forRoot(),
      NgSelectModule,
      ZXingScannerModule,
  ],
  providers: [
      ApiService,
      AuthService,
      SessionService,
      ContainerDataService,
      ModalService,
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
