import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ContainerDataService } from '../service/container-data.service';
import { Container } from '../model/container.model';

@Injectable()
export class ContainerResolver implements Resolve<Observable<Container[]>> {

    constructor(
        private containerDataService: ContainerDataService
    ) {
    }

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Container[]> {
        return this.containerDataService.getAllContainer({'without_mathetial' : true});
    }
}
