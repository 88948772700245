<app-header [header]="headerText" [user]="app.userText"></app-header>
<main>
  <section class="section-form">
    <div class="container">
      <div class="clearfix">
        <!-- <form> -->
        <form [formGroup]="formSearchMaterial" (keydown.enter)="$event.preventDefault()">
          <div class="row">
            <div *ngIf="app.isAdmin" class="col-lg-3  bottom-buttons">
              <a href="/material" class="left-btn"><span>{{app.BUTTON_MATERIAL}}</span></a>
              <a href="/mesid-material" class="left-btn"><span>{{app.BUTTON_MES_ID_MATERIAL}}</span></a>
            </div>
            <div [className]="app.isAdmin ? 'col-lg-5' : 'col-lg-6'">
              <div class="inner">
                <div class="mb-5">
                  <div class="single-inp">
                    <div class="lft">
                      <label>Chargen-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" id="containerId" formControlName="serial_number">
                    </div>
                    <div class="ryt">
                      <button (click)="this.openCameraWindow(app.FIELD_SERIAL_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label>Typ-Teile-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" id="typ_teile_nr" formControlName="device_number">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_DEVICE_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label id="vorrich">Vorrichtungs-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" value="" formControlName="part_number">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_PART_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label>Beschreibung</label>
                      </div>
                    <div class="mid">
                      <input type="text" value="" formControlName="product_description">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_PRODUCT_DESCRIPTION)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="lft">
                      <label>MES-ID</label>
                    </div>
                    <div class="mid">
                      <input type="text" formControlName="mes_id">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_MES_ID)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="lft">
                    </div>
                    <div class="ryt2">
                      <span style="margin-top: -20px">Eingabe oder Handscanner</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [className]="app.isAdmin ? 'col-lg-4' : 'col-lg-6'">
              <div class="inner">
                <div class="fixed-height2">
                  <div class="single-inp">
                    <div class="shorter">
                      <label>Beacon-Nr.</label>
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                          <ng-select class="custom"
                                     [items]="containers"
                                     bindLabel="container_number"
                                     bindValue="container_id"
                                     placeholder="Auswählen"
                                     notFoundText="Nicht gefunden"
                                     [searchFn]="app.searchContainerNumber"
                                     formControlName="container_id">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                      <label>Dichtestes Lesegerät</label>
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                          <input type="text" formControlName="location">
                        </div>
                        <div class="s-inp" style="font-size: 33px">
                          <i class="fa fa-map-marker"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp float-right">
                          <a (click)="reset()" class="image-button">
                            <img src="assets/icon/edit.png" alt="">
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                    </div>
                    <div class="ryt2">
                      <div class="single-btn">
                        <ul class="text-right" style="padding-top: 5px;">
                          <li><button class="select-btn" (click)="searchMaterials()"><i class="fa fa-search"></i> Suchen</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- </form> -->
      </div>
    </div>
  </section>

  <section *ngIf="materials.length > 0" class="black-table">
    <div class="container">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th></th>
            <th>Chargen-Nr. / Vorrichtungs-Nr.</th>
            <th>MES-ID</th>
            <th>Typ-Teile-Nr.</th>
            <th>Beschreibung</th>
            <th>Lokalisierung</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let material of materials">
            <td><input type="radio" (click)="app.goToLocation(material.container_id)"></td>
            <td>{{material.serial_number ? material.serial_number : material.part_number}}</td>
            <td>{{material.mes_id}}</td>
            <td>{{material.device_number}}</td>
            <td>{{material.product_description}}</td>
            <td>{{material.location}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</main>
<app-jw-modal id="custom-modal-3">
  <div class="modal-content">
    <div class="modal-pop-sec">
      <app-scan (valueChange)='displayResult($event)'></app-scan>
    </div>
    <div class="d-flex justify-content-center">
      <button class="select-btn grey" (click)="closeCameraWindow();">Ok</button>
    </div>
  </div>
</app-jw-modal>
