export class Material {

    serial_number = '';
    part_number = '';
    device_number = '';
    location = '';
    is_error: boolean;
    err_msg: string;
    product_description = '';
    material_number = '';
    description = '';
    container_id: number;
    container_number = '';
    mes_id: string;
    material_id: number;
    date = '';
    checked: boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}
