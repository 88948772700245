<script src="../search-materials/search-materials.component.ts"></script>
<app-header [header]="headerText" [user]="app.userText"></app-header>
<main>
  <section class="section-form">
    <div class="container">
      <div class="clearfqix">
        <!-- <form> -->
        <form [formGroup]="formMaterial" (keydown.enter)="$event.preventDefault()">
          <div class="row">
            <div class="col-lg-2  bottom-buttons">
              <a href="/material" class="left-btn"><span>{{app.BUTTON_MATERIAL}}</span></a>
              <a href="/search-materials" class="left-btn"><span>{{app.MATERIAL_BUTTON_SEARCH}}</span></a>
            </div>
            <div class="col-lg-6">
              <div class="inner">
                <div class="mb-5">
                  <div class="single-inp">
                    <div class="lft">
                      <label>Chargen-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" [readOnly]="isReadOnly" [value]="material.serial_number ? material.serial_number : ''" id="serial_number" formControlName="serial_number">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_SERIAL_NUMBER)" class="select-btn" style="min-width: 0">
                          Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label>Typ-Teile-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" [readOnly]="isReadOnly" [value]="material.device_number ? material.device_number: ''" id="device_number" formControlName="device_number">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_DEVICE_NUMBER)" class="select-btn" style="min-width: 0">
                          Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label>Vorrichtungs-Nr.</label>
                    </div>
                    <div class="mid">
                      <input type="text" [readOnly]="isReadOnly" [value]="material.part_number ? material.part_number : ''" id="part_number" formControlName="part_number">
                    </div>
                    <div class="ryt">
                      <button (click)="openCameraWindow(app.FIELD_PART_NUMBER)" class="select-btn" style="min-width: 0">
                          Scan
                      </button>
                    </div>
                  </div>

                  <div class="single-inp">
                    <div class="lft">
                      <label>Beschreibung</label>
                      <span>(Freitextfeld)</span>
                    </div>
                    <div class="mid">
                      <textarea placeholder="max. 255 Zeichen" maxlength="255" [readOnly]="isReadOnly" formControlName="product_description" [value]="material.product_description ? material.product_description: ''"></textarea>
                      <span>Eingabe oder Handscanner</span>
                    </div>
                    <div class="ryt" style="vertical-align: top;">
                      <button (click)="openCameraWindow(app.FIELD_PRODUCT_DESCRIPTION)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-3">
                  </div>
                  <div class="col-sm-9 single-btn" style="padding-left: 45px">
                    <ul *ngIf="!isExists; else elseBlock">
                      <li><button class="select-btn red" (click)="checkMaterial();">Lesen</button></li>
                    </ul>
                    <ng-template #elseBlock>
                      <li><button class="select-btn green" disabled>Lesen</button></li>
                      <li><button class="select-btn" (click)="app.openModal('custom-modal-1'); formMesIdMaterial.get(this.app.FIELD_MES_ID).setValue('');">Neu</button></li>
                    </ng-template>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="inner">
                <div style="height: 359px">
                  <div class="single-inp">
                    <div class="shorter">
                      <label>Beacon-Nr.</label>
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                          <input *ngIf="isExists" type="text" style="width:80px" [readOnly]="isReadOnly" [value]="material.container_number" id="container_id" formControlName="container_id">
                          <ng-select class="custom" *ngIf="!isExists"
                                     [items]="containers"
                                     bindLabel="container_number"
                                     bindValue="container_id"
                                     placeholder="Auswählen"
                                     notFoundText="Nicht gefunden"
                                     [searchFn]="app.searchContainerNumber"
                                     formControlName="container_id">
                          </ng-select>
                        </div>
                        <div *ngIf="isExists" class="s-inp">
                          <a href="/location-material?container_id={{material.container_id}}" class="select-btn" style="padding: 5px 10px;min-width: auto;">Lokalisieren</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                      <label>Dichtestes Lesegerät</label>
                    </div>
                    <div class="ryt2">
                      <div class="inp-inr">
                        <div class="s-inp">
                          <input type="text" [readOnly]="isReadOnly" [value]="material.location" formControlName="location">
                        </div>
                        <div class="s-inp" style="font-size: 33px">
                          <i class="fa fa-map-marker"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="single-inp">
                    <div class="shorter">
                    </div>
                    <div class="ryt2">
                        <div class="inp-inr">
                            <div class="s-inp float-right">
                                <a (click)="reset()" class="image-button">
                                    <img src="assets/icon/edit.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="single-btn">
                  <ul class="text-right">
                    <li><button *ngIf="materials.length > 0" class="select-btn" (click)="deleteMaterials()">NIO entfernen</button></li>
                    <li><button *ngIf="materials.length > 0" class="select-btn red" (click)="deleteMaterials(true)">Alles entfernen</button></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- </form> -->
      </div>
    </div>
  </section>

  <section *ngIf="materials.length > 0" class="black-table">
    <form [formGroup]="formMaterials">
    <div class="container">
      <div class="table-responsive">
        <div class="lft">
          <span>Anzahl: {{lengthMaterials}}</span>
        </div>
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th></th>
            <th>#</th>
            <th>MES-ID</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let material of materials; let i = index">
            <td><input type="checkbox" formControlName="materials" (change)="onChange(material.material_id, $event.target.checked)"></td>
            <td>{{i + 1}}</td>
            <td>{{material.mes_id}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    </form>
  </section>
</main>

<app-jw-modal id="custom-modal-1">
  <div class="modal-content">
      <div class="modal-pop-sec">
        <form [formGroup]="formMesIdMaterial">
          <div>
            <div class="row modal-header">
              <div class="col-md-4" style="padding-top: 10px;"><h6>MES-ID scannen</h6></div>
              <div class="col-md-2">
                  <button (click)="showCamera ? showCamera = false: showCamera = true;this.app.activeScanField = app.FIELD_MES_ID;" class="select-btn" style="min-width: 0">
                      Scan
                  </button>
              </div>
              <div class="col-md-6" style="padding-top: 10px;"><span class="float-right">Anzahl Scanvorgänge: {{lengthMaterials}} von 50</span></div>
            </div>
            <input type="hidden" formControlName="container_id">
            <label style="width: 100% !important;">
              <textarea formControlName="mes_id" rows="7" cols="60" placeholder="{{mesIdPlaceholderText}}" required [autofocus]="formMesIdMaterial"></textarea>
            </label>
          </div>
        </form>
      </div>
      <div class="d-flex justify-content-center">
          <button class="select-btn grey" (click)="createMaterial();app.closeModal('custom-modal-1');">Ok</button>
      </div>
      <div *ngIf="showCamera">
          <br>
          <div class="d-flex justify-content-center"><app-scan (valueChange)='displayResult($event)'></app-scan></div>
      </div>
  </div>
</app-jw-modal>

<app-jw-modal id="custom-modal-2">
  <div class="modal-content">
    <div class="modal-pop-sec">
      <h3>ACHTUNG</h3>
      <p>{{app.beaconNotAssignedText}}</p>
      <ul>
        <li><button class="select-btn grey" (click)="app.closeModal('custom-modal-2');">Ok</button></li>
      </ul>
    </div>
  </div>
</app-jw-modal>

<app-jw-modal *ngIf="!showCamera" id="custom-modal-3">
    <div class="modal-content">
        <div class="modal-pop-sec">
            <app-scan (valueChange)='displayResult($event)'></app-scan>
        </div>
        <div class="d-flex justify-content-center">
          <button class="select-btn grey" (click)="closeCameraWindow();">Ok</button>
        </div>
    </div>
</app-jw-modal>
