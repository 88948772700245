import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {

    public ITEM_ROLE = 'role';
    public ITEM_FIRST_NAME = 'firstName';
    public ITEM_USER_NAME = 'userName';

    public authKey: string;
    public username: string;
    firstName: string;
    public role: string;

    constructor() {
    }

    public destroy(): void {
        this.authKey = '';
        this.username = '';
        this.firstName = '';
        this.role = '';
        localStorage.setItem('authKey', null);
    }

    get getFirstName(): string {
        return this.firstName;
    }

    set setFirstName(value: string) {
        this.firstName = value;
    }

    get getUserName(): string {
        return this.username;
    }

    set setUserName(value: string) {
        this.username = value;
    }
}

