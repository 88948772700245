import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Container} from '../model/container.model';
import {Material} from '../model/material.model';
import {MaterialIds} from '../model/material-ids.model';

const API_MAIN_URL = environment.apiMainUrl;
const API_SLAVE_URL = environment.apiSlaveUrl;
@Injectable({
  providedIn: 'root'
})
// @ts-ignore
export class ApiService {

    constructor(
    private http: HttpClient
    ) {
    }
    public signIn(username: string, password: string): Observable<any> {
        return this.http
            .post(API_MAIN_URL + '/securelogin', {
            username,
            password
          })
          .catch(this.handleError);
    }

    public createContainer(container: Container): Observable<Container> {
        const options = this.getRequestOptions();
        return this.http
          .post(API_SLAVE_URL + '/container', container, options)
          .pipe(
            map(response => {
              return new Container(response);
            })
          )
          .catch(this.handleError);
    }

    public getContainerById(object): Observable<Container> {
        const options = this.getRequestPayload(object);
        return this.http
          .post(API_SLAVE_URL + '/search_containers', options)
          .pipe(
            map(response => {
              return new Container(response);
            })
          )
          .catch(this.handleError);
    }

    public getContainerData(object): Observable<any> {
        const options = this.getRequestPayload(object);
        return this.http
            .post(API_SLAVE_URL + '/get_track_container_data', options)
            .pipe(
                map(response => {
                    return new Container(response);
                })
            )
            .catch(this.handleError);
    }

    public getAllContainer(object: Observable<any>): Observable<Container[]> {
        const options = this.getRequestPayload(object);
        return this.http
            .post(API_SLAVE_URL + '/show_container_list', options)
            .pipe(
                map(response => {
                    if (response.hasOwnProperty('container_list')) {
                        const containers = <any[]> response['container_list'];
                        return containers.map((container) => new Container(container));
                    }
                })
            )
            .catch(this.handleError);
    }

    // material API

    public createMaterial(material: Material): Observable<any> {
        const options = this.getRequestFormData(material);

        return this.http
          .post(API_SLAVE_URL + '/add_scan_material', options)
          .pipe(
              map(response => {
                  return new Material(response);
              })
          )
          .catch(this.handleError);
    }

    public editMaterial(material: Material): Observable<any> {
        const options = this.getRequestFormData(material);

        return this.http
            .post(API_SLAVE_URL + '/edit_container_material', options)
            .pipe(
                map(response => {
                    return new Material(response);
                })
            )
            .catch(this.handleError);
    }

    public searchMaterial(material: Material): Observable<any> {
        const options = this.getRequestPayload(material);

        return this.http
            .post(API_SLAVE_URL + '/search_material', options)
            .pipe(
                map(response => {
                    const result = <any[]> response;
                    if (response.hasOwnProperty('material_list')) {
                        const materials = <any[]> response['material_list'];
                        return materials.map((v) => new Material(v));
                    }
                    return {};
                })
            )
            .catch(this.handleError);
    }

    public searchMaterialByLocation(material: Material): Observable<any> {
        const options = this.getRequestPayload(material);

        return this.http
            .post(API_SLAVE_URL + '/material_tracking_by_location', options)
            .pipe(
                map(response => {
                    const result = <any[]> response;
                    if (response.hasOwnProperty('material_list')) {
                        const materials = <any[]> response['material_list'];
                        return materials.map((v) => new Material(v));
                    }
                    return {};
                })
            )
            .catch(this.handleError);
    }

    public deleteMaterialsFromContainerById(payload: MaterialIds) {
        const options = this.getRequestPayload(payload);
        return this.http
            .post(API_SLAVE_URL + '/remove_all_container_material', options)
            .catch(this.handleError);
    }

    private handleError(error: HttpErrorResponse | any) {
        console.error('ApiService::handleError', error);
        return Observable.throwError(error);
    }

    private getRequestPayload(object) {
        const payload = {
          'auth_key': localStorage.getItem('authKey'),
          'company_id': localStorage.getItem('companyId'),
          'user_id': localStorage.getItem('userId')
        };
        return {...object, ...payload};
    }

    private getRequestFormData(object): FormData {
        const input = new FormData();
        Object.keys(object).forEach(function(key) {
            input.append(key, object[key]);
        });
        input.append('auth_key', localStorage.getItem('authKey'));
        input.append('company_id', localStorage.getItem('companyId'));
        input.append('user_id', localStorage.getItem('userId'));
        return input;
    }

    private getRequestOptions() {
        const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken') // this.session.accessToken
        });
        return { headers };
    }
}
