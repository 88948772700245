import { Component, OnInit} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Material} from '../model/material.model';
import { MaterialDataService} from '../service/material-data.service';
import { AlertService} from '../service/alert.service';
import { ActivatedRoute, Params} from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { AppComponent } from '../app.component';
import { ContainerDataService } from '../service/container-data.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-location-material',
  templateUrl: './location-material.component.html',
  styleUrls: ['./location-material.component.css']
})
export class LocationMaterialComponent implements OnInit {

    public headerText = 'Charge lokalisieren';
    private secondsText = ' Sekunde(n)';
    public material: Material = new Material();
    public materials: Material[] = [];
    public containerId: number;
    public isOnIframeUrl: string;
    public layoutThumbPhoto: string;
    public layoutPhoto: string;
    public deviceNameFirst = '';
    public deviceNameSecond = '';
    public lastSeen = '';

    constructor(
        private materialData: MaterialDataService,
        private containerData: ContainerDataService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private route: ActivatedRoute,
        public sanitizer: DomSanitizer,
        public app: AppComponent,
        private spinnerService: Ng4LoadingSpinnerService,
    ) {
        this.app.setTitle(this.headerText);
        this.app.setHeaderText(this.headerText);
        this.route.queryParamMap
            .map((params: Params) => params.params)
            .subscribe( (params) => {
                if (params && params[this.app.FIELD_CONTAINER_ID]) {
                    this.containerId = params[this.app.FIELD_CONTAINER_ID];
                }
            });
        this.isOnIframeUrl = environment.apiSlaveUrl + '/track_container?user_id=' +
            localStorage.getItem('userId') +
            '&company_id=' + localStorage.getItem('companyId') +
            '&container_id=' + this.containerId + '&auth_key=' +
            localStorage.getItem('authKey');
    }

    ngOnInit() {
        if (this.containerId) {
            this.getAllMaterials();
            this.getContainerDetail();
        }
    }
    // get all materials
    private getAllMaterials() {
        // Submit request to API
        this.materialData
            .searchMaterial(new Material({
                [this.app.FIELD_CONTAINER_ID]: this.containerId,
                [this.app.FIELD_CONTAINER_NUMBER]: null,
                [this.app.FIELD_SERIAL_NUMBER]: null,
                [this.app.FIELD_PART_NUMBER]: null,
                [this.app.FIELD_DEVICE_NUMBER]: null,
                [this.app.FIELD_PRODUCT_DESCRIPTION]: null
            }))
            .subscribe(
                (response) => {
                    if (response.length > 0) {
                        response.forEach((value) => {
                            if (value.serial_number || value.part_number) {
                                this.material = new Material(value);
                                return;
                            }
                        });
                        this.materials = response.filter(v => v.mes_id);
                    } else {
                        this.materials = [];
                    }
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    private getContainerDetail() {
        // Submit the request to the API
        this.spinnerService.show();

        this.material = new Material();
        this.containerData
            .getContainerData({[this.app.FIELD_CONTAINER_ID]: this.containerId})
            .subscribe(
            (response) => {
                if (response.hasOwnProperty('layout_photo')) {
                    this.layoutPhoto = response.layout_photo;
                    this.layoutThumbPhoto = this.layoutPhoto
                        .replace('layout_photo', 'layout_photo/device/thumb');
                    if (response.device.length > 0 ) {
                        this.deviceNameFirst = response.device[0] ? response.device[0].name : '';
                        this.deviceNameSecond = response.device[1] ? response.device[1].name : '';
                    }
                    this.lastSeen = response.last_activity_at ? response.last_activity_at : '';
                    this.spinnerService.hide();
                }
            },
            (error) => {
                this.alertService.error(error);
                this.spinnerService.hide();
            }
        );
    }
}
