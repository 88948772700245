import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription} from 'rxjs';

import { AlertService } from '../service/alert.service';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: AlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
            this.setTimer();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public setTimer() {
        // set message to true to show loading div on view
        setTimeout(() => { this.message   = true; }, 5000);
    }
}
