<app-header [header]="headerText" [user]="app.userText" xmlns="http://www.w3.org/1999/html"></app-header>
<main>
  <section class="section-form">
    <div class="container">
      <div class="clearfix">
        <!-- <form> -->
        <form [formGroup]="formMaterial" (keydown.enter)="$event.preventDefault()">
        <div class="row">
          <div class="col-lg-2  bottom-buttons">
            <a href="/mesid-material" class="left-btn"><span>{{app.BUTTON_MES_ID_MATERIAL}}</span></a>
            <a href="/search-materials" class="left-btn"><span>{{app.MATERIAL_BUTTON_SEARCH}}</span></a>
          </div>
          <div class="col-lg-6">
            <div class="inner">
              <div class="mb-5">
                <div class="single-inp">
                  <div class="lft">
                    <label>Chargen-Nr.</label>
                  </div>
                  <div class="mid">
                    <div class = "input-group">
                      <input type="text" id="serial_number" value="" formControlName="serial_number" class="input-in-line" style="width: 1%">
                      <button (click)="openCameraWindow(app.FIELD_SERIAL_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                  <div class="ryt">
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label>Typ-Teile-Nr.</label>
                  </div>
                  <div class="mid">
                    <div class = "input-group">
                      <input type="text" id="device_number" value="" formControlName="device_number" class="input-in-line" style="width: 1%">
                      <button (click)="openCameraWindow(app.FIELD_DEVICE_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                  <div class="ryt">
                    <span>(optional)</span>
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label>Vorrichtungs-Nr.</label>
                  </div>
                  <div class="mid">
                    <div class = "input-group">
                      <input type="text" id="part_number" value="" formControlName="part_number" class="input-in-line" style="width: 1%">
                      <button (click)="openCameraWindow(app.FIELD_PART_NUMBER)" class="select-btn" style="min-width: 0">
                        Scan
                      </button>
                    </div>
                  </div>
                  <div class="ryt">
                    <span>(oder)</span>
                  </div>
                </div>

                <div class="single-inp">
                  <div class="lft">
                    <label>Beschreibung</label>
                    <span>(Freitextfeld)</span>
                  </div>
                  <div class="mid">
                    <div class = "input-group">
                      <textarea placeholder="max. 255 Zeichen" maxlength="255"  value="" class="input-in-line" formControlName="product_description" style="width: 1%"></textarea>
                      <button (click)="openCameraWindow(app.FIELD_PRODUCT_DESCRIPTION)" class="select-btn" style="min-width: 0;height: fit-content;">
                        Scan
                      </button>
                    </div>
                    <span>Eingabe oder Handscanner</span>
                  </div>
                  <div class="ryt" style="vertical-align: top;">
                    <span>(optional)</span>
                  </div>
                </div>
              </div>

              <div class="row" style="padding-left: 35px">
                <div class="mb-sm-2 single-btn">
                  <ul *ngIf="!isExists; else elseBlock">
                    <li><button class="select-btn red" (click)="createMaterial();">Verknüpfen</button></li>
                    <li><button class="select-btn red" (click)="checkMaterial();">Lesen</button></li>
                  </ul>
                  <ng-template #elseBlock>
                    <li><button class="select-btn red" (click)="updateMaterial();">Aktualisieren</button></li>
                    <li><button class="select-btn green" (click)="goToMesId();">Aufrufen</button></li>
                  </ng-template>
                </div>
            </div>
          </div>
          </div>
          <div class="col-lg-4">
            <div class="inner">
              <div class="fixed-height">
                <div class="single-inp">
                  <div class="shorter">
                    <label>Beacon-Nr.</label>
                  </div>
                  <div class="ryt2">
                    <div class="inp-inr">
                      <div class="clearfix s-inp">
                        <ng-select class="custom" *ngIf="!isExists" style="width:170px"
                                   [items]="containers"
                                   bindLabel="container_number"
                                   bindValue="container_id"
                                   placeholder="Auswählen"
                                   notFoundText="Nicht gefunden"
                                   [searchFn]="app.searchContainerNumber"
                                   formControlName="container_id">
                        </ng-select>
                        <!--<select *ngIf="!isExists" formControlName="container_id">-->
                          <!--<option value="">Auswählen</option>-->
                          <!--<option [value]="container.container_id" *ngFor="let container of containers">{{container.container_number}}</option>-->
                        <!--</select>-->
                        <select *ngIf="isExists" formControlName="container_id" style="width:170px">
                          <option [value]="material.container_id">{{material.container_number}}</option>
                        </select>
                      </div>
                      <div class="s-inp">

                      </div>
                    </div>
                  </div>
                </div>
                <div class="single-inp">
                  <div class="shorter">
                  </div>
                  <div class="ryt2">
                    <div class="inp-inr">
                      <div class="s-inp float-right">
                        <a (click)="reset()" class="image-button">
                          <img src="assets/icon/edit.png" alt="">
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="single-btn">
                <ul class="text-right">
                  <li><button *ngIf="!isExists" class="select-btn red" disabled>Verknüpfung löschen</button></li>
                  <li><button *ngIf="isExists" class="select-btn red" (click)="deleteMaterials()">Verknüpfung löschen</button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </form>
        <!-- </form> -->
      </div>
    </div>
  </section>
</main>

<app-jw-modal id="custom-modal-1">
  <div class="modal-content">
    <div class="modal-pop-sec">
      <h3>ACHTUNG</h3>
      <p>{{app.beaconNotAssignedText}}</p>
      <ul>
        <li><button class="select-btn grey" (click)="app.closeModal('custom-modal-1');">Ok</button></li>
      </ul>
    </div>
  </div>
</app-jw-modal>
<app-jw-modal id="custom-modal-3">
  <div class="modal-content">
    <div class="modal-pop-sec">
      <app-scan (valueChange)='displayResult($event)'></app-scan>
    </div>
    <div class="d-flex justify-content-center">
      <button class="select-btn grey" (click)="closeCameraWindow();">Ok</button>
    </div>
  </div>
</app-jw-modal>
