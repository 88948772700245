import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Material} from '../model/material.model';
import {MaterialDataService} from '../service/material-data.service';
import {ApiService} from '../service/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../service/alert.service';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {AppComponent} from '../app.component';
import {ContainerDataService} from '../service/container-data.service';
import {Container} from '../model/container.model';
import {ScanComponent} from '../_components/scan/scan.component';

@Component({
  selector: 'app-search-materials',
  templateUrl: './search-materials.component.html',
  styleUrls: ['./search-materials.component.css']
})
// @ts-ignore
export class SearchMaterialsComponent implements OnInit {

    // @ts-ignore
    @ViewChild(ScanComponent) scanner: ScanComponent;

    public materials: Material[] = [];
    public headerText = 'Suchfunktion';
    private inputFieldsError = 'Bitte geben Sie einen Suchbegriff in eines der Suchfelder ein.';
    private resultSuccess = 'Die Suche lieferte ein oder mehrere Suchergebnis/-se.';
    private resultEmpty = 'Die Suche lieferte kein Suchergebnis.';
    public containers: Container[];

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private alertService: AlertService,
        private materialData: MaterialDataService,
        private spinnerService: Ng4LoadingSpinnerService,
        public app: AppComponent,
        private containerData: ContainerDataService,
    ) {

        this.app.setTitle(this.headerText);
        this.app.setHeaderText(this.headerText);
        this.formSearchMaterial = fb.group({
            serial_number: [],
            part_number: [],
            device_number: [],
            product_description: [],
            mes_id:  [],
            container_id:  [],
            location: []
        });
    }

    public formSearchMaterial: FormGroup;
    ngOnInit() {
        this.getContainers();
    }
    public searchMaterials() {
        // Make sure form values are valid
        if (!this.formSearchMaterial.getRawValue()) {
            this.alertService.error(this.inputFieldsError);
            return;
        }
        this.spinnerService.show();

        // Grab values from form
        const material = new Material(this.formSearchMaterial.getRawValue());
        // set container_number as null for search
        material.container_number = null;
        // Keep an array of elements whose id is added in filtered array
        const elementId = [];
        this.materials = [];

        // Submit request to API
        if (this.formSearchMaterial.get(this.app.FIELD_LOCATION).value) {
            this.materialData
                .searchMaterialByLocation(material)
                .subscribe(
                    (response) => {
                        if (response.length > 0) {
                            this.materials = response.filter(v => {
                                return !v.serial_number || !v.part_number;
                            });
                        }
                        if (this.materials.length > 0) {
                            this.alertService.success(this.resultSuccess);
                        } else {
                            this.materials = [];
                            this.alertService.success(this.resultEmpty);
                        }
                    },
                    (error) => {
                        this.alertService.error(error);
                    },
                    () => this.spinnerService.hide()
                );
        } else {
            this.materialData
                .searchMaterial(material)
                .subscribe(
                    (response) => {
                        if (response.length > 0) {
                            if (this.formSearchMaterial.get(this.app.FIELD_MES_ID).value) {
                                this.materials = response;
                            } else {
                                this.materials = response.filter(v => {
                                    if (!v.container_id) {
                                        return false;
                                    }
                                    if (elementId.indexOf(v.container_id) === -1) {
                                        // If not present in array, then add it
                                        elementId.push(v.container_id);
                                        return true;
                                    } else {
                                        // Already present in array, don't add it
                                        return false;
                                    }
                                });
                                // if search result has only once row, redirect to location page
                                if (this.materials.length === 1) {
                                    this.app.goToLocation(this.materials[0].container_id);
                                }
                            }
                            this.alertService.success(this.resultSuccess);
                        } else {
                            this.materials = [];
                            this.alertService.success(this.resultEmpty);
                        }
                    },
                    (error) => {
                        this.alertService.error(error);
                    },
                    () => this.spinnerService.hide()
                );
        }
    }

    public reset() {
        this.formSearchMaterial.reset();
        this.materials = [];
    }

    // Get all assigned containers
    private getContainers() {
        this.containerData
            .getAllContainer({'without_mathetial' : false})
            .subscribe(
                (response) => {
                    this.containers = response;
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    public openCameraWindow(activeField) {
        this.app.openModal('custom-modal-3');
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange(localStorage.getItem('selectedCamera'));
        }
        this.app.activeScanField = activeField;
    }

    public closeCameraWindow() {
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange('');
        }
        this.app.closeModal('custom-modal-3');
    }

    public displayResult(qrResultString) {
        // close pop-up window and switch off camera
        this.closeCameraWindow();
        this.formSearchMaterial.get(this.app.activeScanField).setValue(qrResultString);
    }
}
