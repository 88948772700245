export class Container {

  container_id: number;
  container_number: string;
  device_number: string;
  last_activity_at: string;
  private disabled?: boolean;
  is_beacon: boolean;
  color_code: string;
  photo: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  setDisabled() {
    const hours = Math.abs(new Date().valueOf() - new Date(this.last_activity_at).valueOf()) / 36e5;
    this.disabled = hours > 1;
  }
}
