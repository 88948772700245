<div [hidden]="!hasDevices">
  <select (change)="onDeviceSelectChange($event.target.value)">
    <option value="" [selected]="!selectedDevice">{{noDeviceText}}</option>
    <option *ngFor="let device of availableDevices" [value]="device.deviceId" [selected]="selectedDevice && device.deviceId === selectedDevice.deviceId">{{device.label}}</option>
  </select>
</div>
<div [hidden]="!hasCameras">
  <h2 *ngIf="!selectedDevice">{{noCameraText}}</h2>
  <zxing-scanner #scanner [torch]="torchEnabled" [(device)]="selectedDevice" (scanSuccess)="onCodeResult($event)"
                 [formats]="allowedFormats" [tryHarder]="tryHarder" (hasDevices)="hasDevices = $event"
                 (permissionResponse)="hasPermission = $event"></zxing-scanner>
</div>
<div *ngIf="!hasCameras && hasPermission === true">
  <h3>{{deviceDoesHaveCameraText}} </h3>
</div>
<div *ngIf="hasPermission === undefined">
  <h3>{{permissionsText}}</h3>
  <blockquote>
    <h4>{{permissionsWithText}}</h4>
  </blockquote>
</div>
<div *ngIf="hasPermission === false">
  <h3>{{deniedPermissionsText}}</h3>
</div>
