import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { AlertService} from '../service/alert.service';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public frm: FormGroup;

  public isBusy = false;
  public hasFailed = false;
  public showInputErrors = false;
  dateNow: Date = new Date();

  // texts
  public login = 'Benutzername';
  public password = 'Passwort';
  public errorLogin = 'Bitte geben Sie Ihren Benutzernamen ein';
  public errorPassword = 'Bitte geben Sie Ihr Passwort ein';
  public version = '0.1';

  constructor(
    private api: ApiService,
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private alertService: AlertService,
    private app: AppComponent
  ) {
    // logout
    this.auth.doSignOut();
    this.frm = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  public doSignIn() {

    // Make sure form values are valid
    if (this.frm.invalid) {
      this.showInputErrors = true;
      this.alertService.error('Login oder Passwort ist falsch');
      return;
    }

    // Reset status
    this.isBusy = true;
    this.hasFailed = false;

    // Grab values from form
    const username = this.frm.get('username').value;
    const password = Md5.hashStr(this.frm.get('password').value).toString();

    // Submit request to API
    this.api
      .signIn(username, password)
      .subscribe(
        (response) => {
          this.auth.doSignIn(
              response.auth_key,
              response.username,
              response.firstname,
              response.company_id,
              response.user_id,
              response.role
          );
          this.app.setIsAdmin();
          this.app.setUserText();
          this.router.navigate(['material']);
        },
        (error) => {
          this.alertService.error(error);
          this.isBusy = false;
          this.hasFailed = true;
        }
      );
  }
}
