import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // do stuff with response and headers you want

                    const body = event.body;
                    if (body.hasOwnProperty('err_msg') && body.hasOwnProperty('err_msg')) {
                        const f = body.err_msg;
                        if (f === 'Invalid login information' || f  === 'unauthorized access') {
                            this.authService.doSignOut();
                            location.reload(true);
                        }
                    }
                }
                return event;
            })
            .pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authService.doSignOut();
                location.reload(true);
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
