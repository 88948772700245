import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../service/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../service/alert.service';
import {MaterialDataService} from '../service/material-data.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Material} from '../model/material.model';
import {MaterialIds} from '../model/material-ids.model';
import {AppComponent} from '../app.component';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ContainerDataService} from '../service/container-data.service';
import {Container} from '../model/container.model';
import {ScanComponent} from '../_components/scan/scan.component';

@Component({
  selector: 'app-mesid-material',
  templateUrl: './mesid-material.component.html',
  styleUrls: ['./mesid-material.component.css']
})


export class MesidMaterialComponent implements OnInit {

    // @ts-ignore
    @Input() material: Material;
    // @ts-ignore
    @ViewChild(ScanComponent) scanner: ScanComponent;

    private mesIdText = 'MES-ID';
    public headerText = 'Charge mit MES-IDs befüllen';
    private createMesIdText = 'MES-ID(s) wurde(n) erfolgreich hinzugefügt.';
    private deleteMesIdText = 'MES-ID(s) wurde(n) erfolgreich gelöscht.';
    private inputMesIdError = 'Bitte geben Sie eine MED-ID ein.';
    private maxCountMesIdError = 'Die maximale Anzahl von MES-IDs wurde erreicht.';
    private eraseButtonText = 'Bitte geben Sie eine Chargen-Nr. oder Vorrichtungs-Nr. ein.';
    private beaconWasFoundText = 'Die Charge/Vorrichtung ist mit einem Beacon verknüpft.';
    public mesIdPlaceholderText = 'Bitte nutzen Sie einen Handscanner mit Enter-Funktion';

    public materials: Material[] = [];
    public formMaterial: FormGroup;
    public formMesIdMaterial: FormGroup;
    public formMaterials: FormGroup;
    public isExists = false;
    public isReadOnly = false;
    public lengthMaterials = 0;
    private maxCountMaterials = 50;
    public idsFormArray: Array<any> = [];
    public containers: Container[];
    public showCamera = false;
    private previousScanResult = '';

    constructor(
        private api: ApiService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private alertService: AlertService,
        private materialData: MaterialDataService,
        public app: AppComponent,
        private spinnerService: Ng4LoadingSpinnerService,
        private containerData: ContainerDataService,
    ) {
        this.app.setTitle(this.headerText);
        this.app.setHeaderText(this.headerText);
        this.formMaterial = fb.group({
            serial_number: ['', Validators.required],
            part_number: ['', Validators.required],
            device_number: [''],
            product_description: [''],
            container_id:  [null, Validators.required],
            location: ['']
        });
        this.formMesIdMaterial = fb.group({
            mes_id: ['', Validators.required],
            container_id: ['', Validators.required],
        });
        this.formMaterials = fb.group({
            materials: [],
        });
    }

    ngOnInit() {
        // get material from localStorage and apply it to the local object
        this.material = localStorage.getItem('material') ? JSON.parse(
            localStorage.getItem('material')
        ) : new Material();
        // set value for container_id in the modal
        this.formMesIdMaterial.get(this.app.FIELD_CONTAINER_ID).setValue(this.material.container_id);

        if (this.material.serial_number || this.material.part_number) {
            this.isExists = true;
        }

        this.getAllMaterials();
        this.getContainers();

        this.formMaterial.valueChanges
            .pipe(debounceTime(1), distinctUntilChanged())
            .subscribe(changes => {
                if (changes.serial_number !== '' && changes.serial_number != null) {
                    this.formMaterial.get(this.app.FIELD_PART_NUMBER).disable();
                } else {
                    this.formMaterial.get(this.app.FIELD_PART_NUMBER).enable();
                    if (changes.part_number !== '' && changes.part_number != null) {
                        this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).disable();
                    } else {
                        this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).enable();
                    }
                }
                if (this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).value === ''
                    && this.formMaterial.get(this.app.FIELD_PART_NUMBER).value === ''
                    && this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER).value === ''
                    && this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION).value === ''
                    && this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value === ''
                    && this.formMaterial.get(this.app.FIELD_LOCATION).value === ''
                ) {
                    this.isExists = false;
                }
            });
    }

    // Simulate GET /containers/:id
    public createMaterial() {
        // close camera
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange('');
        }
        // Grab values from form
        const mesIds = this.formMesIdMaterial.get(this.app.FIELD_MES_ID)
            .value.toString().split('\n').filter(v => v.length > 0);
        // Make sure form values are valid
        if (this.formMesIdMaterial.invalid) {
            this.alertService.error(this.inputMesIdError);
            return;
        }
        // Make sure that the counts of materials is 50 or less
        if (this.materials.length === this.maxCountMaterials ||
            ((this.materials.length + mesIds.length) >= this.maxCountMaterials)) {
            this.alertService.error(this.maxCountMesIdError);
            return;
        }

        mesIds.forEach((value, index) => {
            // create material object
            const material = new Material(
                {
                    [this.app.FIELD_MES_ID]: value,
                    [this.app.FIELD_CONTAINER_ID] : this.material.container_id
                });
            // Saving in material_number and description values
            // for being aware where this material was created
            material.material_number = value;
            material.description = this.mesIdText;

            // Submit request to API
            this.materialData
                .createMaterial(material)
                .subscribe(
                    (response) => {
                        if (index === mesIds.length - 1) {
                            this.alertService.success(this.createMesIdText);
                            this.getAllMaterials();
                            this.formMesIdMaterial.get(this.app.FIELD_MES_ID).setValue(null);
                        }
                    },
                    (error) => {
                        this.alertService.error(error);
                        this.getAllMaterials();
                    }
                );
        });
    }
    // search material by serial_number and part_number
    public checkMaterial() {
        this.spinnerService.show();
        // Make sure form values are valid
        if (this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).invalid &&
            this.formMaterial.get(this.app.FIELD_CONTAINER_ID).invalid
        ) {
            this.alertService.error(this.eraseButtonText);
            return;
        }
        // Grab values from form
        const material = new Material({
            serial_number: this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).value ?
                this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER).value : '',
            part_number: this.formMaterial.get(this.app.FIELD_PART_NUMBER).value ?
                this.formMaterial.get(this.app.FIELD_PART_NUMBER).value : '',
            container_id: this.formMaterial.get(this.app.FIELD_CONTAINER_ID).value
        });

        // Submit request to API
        this.materialData
            .searchMaterial(material)
            .subscribe(
                (response) => {
                    if (response.length > 0) {
                        response.forEach((value) => {
                            if (
                                (value.serial_number === material.serial_number || value.part_number === material.part_number)
                                && value.container_id
                            ) {
                                this.isExists = true;
                                this.material = new Material(value);
                                // set values in the forms
                                this.formMaterial.get(this.app.FIELD_SERIAL_NUMBER)
                                    .setValue(this.material.serial_number);
                                this.formMaterial.get(this.app.FIELD_DEVICE_NUMBER)
                                    .setValue(this.material.device_number);
                                this.formMaterial.get(this.app.FIELD_PART_NUMBER)
                                    .setValue(this.material.part_number);
                                this.formMaterial.get(this.app.FIELD_PRODUCT_DESCRIPTION)
                                    .setValue(this.material.product_description);
                                this.formMaterial.get(this.app.FIELD_CONTAINER_ID)
                                    .setValue(this.material.container_id);
                                this.formMaterial.get(this.app.FIELD_LOCATION)
                                    .setValue(this.material.location);
                                this.formMesIdMaterial.get(this.app.FIELD_CONTAINER_ID)
                                    .setValue(this.material.container_id);
                                this.getAllMaterials();
                            }
                        });
                    }
                    if (this.isExists) {
                       this.alertService.success(this.beaconWasFoundText);
                    } else {
                        this.app.openModal('custom-modal-2');
                        this.isExists = false;
                    }
                    this.spinnerService.hide();
                },
                (error) => {
                    this.spinnerService.hide();
                    this.alertService.error(error);
                }
            );
    }

    public reset() {
        this.isReadOnly = false;
        this.isExists = false;
        this.formMaterial.reset();
        this.formMesIdMaterial.reset();
        this.materials = [];
        this.material = new Material();
        localStorage.setItem('material', JSON.stringify(new Material()));
    }

    // get all materials
    private getAllMaterials() {
        // Submit request to API
        this.materialData
            .searchMaterial(new Material({
                [this.app.FIELD_CONTAINER_ID]: this.material.container_id,
                [this.app.FIELD_CONTAINER_NUMBER]: null,
                [this.app.FIELD_SERIAL_NUMBER]: null,
                [this.app.FIELD_PART_NUMBER]: null,
                [this.app.FIELD_DEVICE_NUMBER]: null,
                [this.app.FIELD_PRODUCT_DESCRIPTION]: null
            }))
            .subscribe(
                (response) => {
                    if (response.length > 0) {
                        this.materials = response.filter(v => v.mes_id);
                    } else {
                        this.materials = [];
                    }
                    this.lengthMaterials = this.materials.length;
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    // delete materials by ids
    public deleteMaterials(isAll: boolean = false) {
        const ids = isAll ?
            this.materials.map(opt => opt.material_id) :
            this.idsFormArray;

        const payload = new MaterialIds({
            [this.app.FIELD_MATERIAL_IDS] : ids,
            [this.app.FIELD_CONTAINER_ID] : this.material.container_id
        });

        this.materialData
            .deleteMaterialsFromContainerById(payload)
            .subscribe(
                (response) => {
                    this.alertService.success(this.deleteMesIdText);
                    this.formMaterials.reset();
                    this.getAllMaterials();
                    this.idsFormArray = [];
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    // Get all assigned containers
    private getContainers() {
        this.containerData
            .getAllContainer({'without_mathetial' : false})
            .subscribe(
                (response) => {
                    this.containers = response;
                },
                (error) => {
                    this.alertService.error(error);
                }
            );
    }

    onChange(id: string, isChecked: boolean) {
        if (isChecked) {
            this.idsFormArray.push(id);
        } else {
            const index = this.idsFormArray.indexOf(id);
            this.idsFormArray.splice(index, 1);
        }
    }

    public openCameraWindow(activeField) {
        this.app.openModal('custom-modal-3');
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange(localStorage.getItem('selectedCamera'));
        }
        this.app.activeScanField = activeField;
    }

    public closeCameraWindow() {
        if (this.scanner.hasPermission) {
            this.scanner.onDeviceSelectChange('');
        }
        this.app.closeModal('custom-modal-3');
    }

    public displayResult(qrResultString) {
        // close pop-up window and switch off camera
        if (this.app.activeScanField === this.app.FIELD_MES_ID) {
            if (this.previousScanResult !== qrResultString) {
                this.previousScanResult = qrResultString;
                const newValue = this.formMesIdMaterial.get(this.app.activeScanField).value + qrResultString + '\n';
                this.formMesIdMaterial.get(this.app.activeScanField).setValue(newValue);
            }
        } else {
            this.closeCameraWindow();
            this.formMaterial.get(this.app.activeScanField).setValue(qrResultString);
        }
    }
}
